import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@material-tailwind/react";
import { Carousel } from "react-responsive-carousel";
import logo from "../assets/logo.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Landing = () => {
  const navigate = useNavigate();
  const getCarouselProps = () => {
    if (window.innerWidth >= 1024) {
      // Desktop screens
      return {
        axis: "horizontal",
        showArrows: true,
      };
    } else {
      // Mobile screens
      return {
        axis: "vertical",
        showArrows: false,
      };
    }
  };

  return (
    <div className="w-full h-full relative ">
      <div className="w-full h-full  bg-black/75  ">
        <Carousel
          {...getCarouselProps()}
          autoPlay
          swipeable
          infiniteLoop
          showThumbs={false}
          useKeyboardArrows={true}
          stopOnHover
          interval={6000}
          transitionTime={500}
          showStatus
          emulateTouch={true}
          autoFocus
        >
          {/* Slide 1 */}
          <div className="relative h-screen w-full  ">
            <img
              src={require("../assets/landing/retine_salik.jpg")}
              alt="SoftFloor Rubber Tile"
              className="h-full w-full object-cover"
            />

            <div
              className="absolute inset-0 grid h-full w-full md:p-4 
                            place-items-center bg-black/75"
            >
              <div className="w-3/4 text-center flex flex-col items-center md:w-2/4 md:h-2/3 lg:h-2/3">
                <img
                  className="h-64 w-38 mb-4 sm:h-24 md:h-24 lg:h-64 "
                  src={logo}
                  alt="SoftFloor"
                />
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 sm:mb-6 md:mb-6 lg:mb-12 opacity-80 sm:text-sm 
                             md:text-sm lg:text-xl text-center uppercase"
                >
                  ՀԱՏԱԿԻ ՌԵՏԻՆԵ ՍԱԼԻԿՆԵՐ ԵՎ ԾԱԾԿԵՐ ԱՆՄԻՋԱՊԵՍ ԱՐՏԱԴՐՈՂԻՑ
                </Typography>
                <div className="flex justify-center flex-wrap">
                  <Button
                    size="lg"
                    color="white"
                    onClick={() => navigate("/products")}
                    className="sm:px-4 sm:py-2  md:px-4 md:py-2 lg:px-6 lg:py-3 "
                  >
                    Տեսնել ավելին
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* Slide 2 */}
          <div className="relative h-screen w-full  ">
            <img
              src={require("../assets/landing/qare_gorg.jpg")}
              alt="SoftFloor Rubber Tile"
              className="h-full w-full object-cover"
            />
            <div
              className="absolute inset-0 grid h-full w-full items-center sm:pt-8 
                         lg:pt-0 bg-black/75"
            >
              <div
                className="flex flex-col w-3/4 ml-12 sm:items-start md:items-start 
                              md:w-2/4 md:pl-20 lg:pl-32"
              >
                <Typography
                  variant="h2"
                  color="white"
                  className="mb-4 text-3xl sm:text-xl md:text-xl lg:text-5xl sm:whitespace-nowrap uppercase"
                >
                  ՔԱՐԵ ԳՈՐԳ եվ Քարե սալիկներ{" "}
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80 sm:text-left sm:text-sm sm:mb-6 
                  md:text-sm md:mb-6 lg:text-xl lg:mb-12 uppercase"
                >
                  Քարե սալիկները և գորգը երկարակյաց, էկոլոգիապես մաքուր և
                  ջրաթափանց ծածկույթներ են՝ պատրաստված բնական քարերից և
                  պոլիմերային սոսինձից
                </Typography>
                <div className="flex justify-center gap-4 flex-wrap md:gap-8">
                  <Button
                    size="lg"
                    color="white"
                    onClick={() => navigate("/stone_carpet")}
                    className="sm:px-4 sm:py-2 md:px-4 md:py-2 lg:px-6 lg:py-3 "
                  >
                    Տեսնել ավելին
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="relative h-screen w-full ">
            <img
              src={require("../assets/landing/retine_hatak.jpg")}
              alt="SoftFloor Rubber Seamless"
              className="h-full w-full object-cover"
            />
            <div
              className="absolute inset-0 grid h-full w-full items-center sm:pt-8 
                         lg:pt-0 bg-black/75"
            >
              <div
                className="flex flex-col w-3/4 ml-12 sm:items-start md:items-start 
                              md:w-2/4 md:pl-20 lg:pl-32"
              >
                <Typography
                  variant="h2"
                  color="white"
                  className="mb-4 text-3xl sm:text-xl md:text-xl lg:text-5xl sm:whitespace-nowrap  "
                >
                  ՌԵՏԻՆԵ ՀԱՏԱԿ
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80 sm:text-left sm:text-sm sm:mb-6 
                             md:text-sm md:mb-6 lg:text-xl lg:mb-12 uppercase"
                >
                  Միաձույլ ռետինե հատակների պատրաստման ժամանակ մենք օգտագործում
                  ենք նախօրոք ներկված ռետինե փշրանքներ
                </Typography>
                <div className="flex justify-center gap-4 flex-wrap md:gap-8">
                  <Button
                    size="lg"
                    color="white"
                    onClick={() => navigate("/retine_hatak")}
                    className="sm:px-4 sm:py-2 md:px-4 md:py-2 lg:px-6 lg:py-3 "
                  >
                    Տեսնել ավելին
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* Slide 4 */}
          <div className="relative h-screen w-full ">
            <img
              src={require("../assets/landing/retine_salik_1.jpg")}
              alt="SoftFloor Rubber Tile"
              className="h-full w-full object-cover"
            />
            <div
              className="absolute inset-0 grid h-full w-full items-center 
                            md:items-end md:text-left sm:pt-8 lg:pt-0  bg-black/75"
            >
              <div className=" w-3/4 ml-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
                <Typography
                  variant="h2"
                  color="white"
                  className="mb-4 text-3xl sm:text-xl sm:text-left md:text-xl lg:text-5xl 
                             sm:whitespace-nowrap uppercase"
                >
                  ռետինե սալիկ
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80 sm:text-left 
                             md:text-left sm:text-sm sm:mb-6 md:text-sm md:mb-6 
                             lg:text-xl lg:mb-12 uppercase"
                >
                  Հատակի ռետինե սալիկները մեզ մոտ արտադրվում են սառը սեղմման
                  տեխնոլոգիայի կիրառմամբ
                </Typography>
                <div className="flex justify-center sm:justify-start gap-4 flex-wrap md:gap-8">
                  <Button
                    size="lg"
                    color="white"
                    onClick={() => navigate("/retine_salik")}
                    className="sm:px-4 sm:py-2  md:px-4 md:py-2 lg:px-6 lg:py-3 "
                  >
                    Տեսնել ավելին
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Landing;
