export const tileItems = [
  {
    id: 1,
    image: require("../assets/tile/retine_salik_black.jpg"),
    title: "Ռետինե սալիկ սև",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 2,
    image: require("../assets/tile/retine_salik_blue.jpg"),
    title: "Ռետինե սալիկ կապույտ",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 3,
    image: require("../assets/tile/retine_salik_brown.jpg"),
    title: "Ռետինե սալիկ շագանակագույն",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 4,
    image: require("../assets/tile/retine_salik_green.jpg"),
    title: "Ռետինե սալիկ կանաչ",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 5,
    image: require("../assets/tile/retine_salik_orange.jpg"),
    title: "Ռետինե սալիկ նարնջագույն",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 6,
    image: require("../assets/tile/retine_salik_red.jpg"),
    title: "Ռետինե սալիկ կարմիր",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 7,
    image: require("../assets/tile/retine_salik_yellow.jpg"),
    title: "Ռետինե սալիկ դեղին",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 8,
    image: require("../assets/tile/retine_salik_gray.jpg"),
    title: "Ռետինե սալիկ  մոխրագույն",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },

  {
    id: 9,
    image: require("../assets/tile/retine_ezraqar.jpg"),
    title: "Ռետինե սալիկ  եզրաքար",
    size: "Չափս ՝ 500*210 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 10,
    image: require("../assets/tile/retine_salik_black_yellow.jpg"),
    title: "Ռետինե սալիկ Միքս  սև և դեղին",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 11,
    image: require("../assets/tile/retine_salik_blue_black.jpg"),
    title: "Ռետինե սալիկ Միքս կապույտ և սև",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 12,
    image: require("../assets/tile/retine_salik_yellow_black.jpg"),
    title: "Ռետինե սալիկ Միքս  դեղին և սև",
    size: "Չափս ՝ 500*500 մմ",
    description:
      "Բաղադրությունը՝ էկոլոգիապես մաքուր ռետինե(SBR) կամ կաուչուկե (EPDM) փշրանքներ, պոլիուրեթանային սոսինձ և պիգմենտային ներկ: ",
  },
  {
    id: 13,
    image: require("../assets/tile/retine_hatik.jpg"),
    title: "Ռետինե փշուր",
    description:
      "Ռետինե փշրանքը  անվադողերի վերամշակման արդյունքում ստացվող հումք է: Վերամշակման գործընթացում անվադողերի  թելերը և մետաղալարը հանվում են՝ թողնելով անվադողի ռետինը:  ",
  },
];

export const navigation = [
  { name: "Գլխավոր էջ", to: "/", current: false },
  { name: "Ռետինե սալիկներ", to: "/retine_salik", current: false },
  { name: "ՄԻԱձՈՒՅԼ ՌԵՏԻՆԵ ՀԱՏԱԿՆԵՐ", to: "/retine_hatak", current: false },
  { name: "ՔԱՐԵ ԳՈՐԳ եվ Քարե սալիկներ", to: "/stone_carpet", current: false },
  { name: "արտադրանքի մասին", to: "/products", current: false },
  { name: "ԿԱՊ", to: "/contact", current: false },
];

export const photos = [
  {
    src: require("../assets/collage/retine_hatak_1.jpg"),
    width: 1080,
    height: 800,
  },
  {
    src: require("../assets/collage/retine_hatak_2.jpg"),
    width: 650,
    height: 620,
  },
  {
    src: require("../assets/collage/retine_hatak_3.jpg"),
    width: 1080,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_4.jpg"),
    width: 1440,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_5.jpg"),
    width: 1080,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_6.jpg"),
    width: 1080,
    height: 607,
  },
  {
    src: require("../assets/collage/retine_hatak_7.jpg"),
    width: 980,
    height: 608,
  },
  {
    src: require("../assets/collage/retine_hatak_8.jpg"),
    width: 1360,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_9.jpg"),
    width: 800,
    height: 500,
  },
  {
    src: require("../assets/collage/retine_hatak_10.jpg"),
    width: 1080,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_11.jpg"),
    width: 1080,
    height: 1110,
  },
  {
    src: require("../assets/collage/retine_hatak_12.jpg"),
    width: 1080,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_13.jpg"),
    width: 1080,
    height: 694,
  },
  {
    src: require("../assets/collage/retine_hatak_14.jpg"),
    width: 600,
    height: 600,
  },

  {
    src: require("../assets/collage/retine_hatak_15.jpg"),
    width: 1080,
    height: 694,
  },
  {
    src: require("../assets/collage/retine_hatak_16.jpg"),
    width: 1080,
    height: 810,
  },
  {
    src: require("../assets/collage/retine_hatak_17.jpg"),
    width: 1440,
    height: 720,
  },
  {
    src: require("../assets/collage/retine_hatak_18.jpg"),
    width: 800,
    height: 500,
  },
  {
    src: require("../assets/collage/retine_hatak_19.jpg"),
    width: 450,
    height: 500,
  },
  {
    src: require("../assets/collage/retine_hatak_20.jpg"),
    width: 450,
    height: 500,
  },
  {
    src: require("../assets/collage/retine_hatak_21.jpg"),
    width: 1440,
    height: 1440,
  },
];

export const carpetStoneItems = [
  {
    id: 1,
    image: require("../assets/stone/stone01.jpg"),
    title: "Քարե գորգ 101",
    description:
      "Բաղադրությունը՝ տարբեր ֆրակցիաների բնական քարեր և  պոլիմերային սոսինձ",
  },
  {
    id: 2,
    image: require("../assets/stone/stone02.jpg"),
    title: "Քարե գորգ 102",
    description:
      "Բաղադրությունը՝ տարբեր ֆրակցիաների բնական քարեր և  պոլիմերային սոսինձ",
  },
  {
    id: 3,
    image: require("../assets/stone/stone03.jpg"),
    title: "Քարե գորգ 103",
    description:
      "Բաղադրությունը՝ տարբեր ֆրակցիաների բնական քարեր և  պոլիմերային սոսինձ",
  },
  {
    id: 4,
    image: require("../assets/stone/stone04.jpg"),
    title: "Քարե գորգ 104",
    description:
      "Բաղադրությունը՝ տարբեր ֆրակցիաների բնական քարեր և  պոլիմերային սոսինձ",
  },
  {
    id: 5,
    image: require("../assets/stone/stone05.jpg"),
    title: "Քարե գորգ 105",
    description:
      "Բաղադրությունը՝ տարբեր ֆրակցիաների բնական քարեր և  պոլիմերային սոսինձ",
  },
];
