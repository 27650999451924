import React, { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import Header from "./Layouts/Header";
import { routes } from "./router/routes";
import bg_product from "./assets/bg-product.jpg";
import GoogleAnalytics from "./googleAnalytics/GoogleAnalytics";

function App() {
  let element = useRoutes(routes);
  let location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  const isLandingPage = location.pathname === "/";

  return (
    <div
      className="relative min-h-full "
      style={{
        backgroundImage: `url(${bg_product})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        fontFamily: "'Mardoto', sans-serif",
      }}
    >
      <GoogleAnalytics measurementId="G-SG6858MFCT" />

      {isLandingPage ? (
        <div className="absolute z-50  w-full ">
          <Header />
        </div>
      ) : (
        <Header />
      )}

      {element}
    </div>
  );
}

export default App;
