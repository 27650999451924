import Seamless from "../pages/Seamless";
import Tile from "../pages/Tile";
import Landing from "../pages/Landing";
import NotFound from "../pages/NotFound";
import Products from "../pages/Products";
import StoneCarpet from "../pages/StoneCarpet";
import Contact from "../pages/Contact";

export const routes = [
  {
    path: "/",
    element: <Landing />,
  },

  {
    path: "/retine_hatak",
    element: <Seamless />,
  },
  {
    path: "/retine_salik",
    element: <Tile />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/stone_carpet",
    element: <StoneCarpet />,
  },
];
