import React from "react";
import AddressMap from "../components/AddressMap";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div className="w-full  h-full  md:min-h-screen  lg:h-screen  flex flex-col  items-center  gap-4 py-4 md:py-8 lg:py-4">
      <motion.div
        className="md:w-9/12 lg:w-7/12 lg:h-[30rem] bg-black/50 flex items-center justify-evenly rounded-3xl"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 60,
          damping: 20,
          ease: "easeInOut",
          duration: 1,
        }}
      >
        <div
          className="w-full flex flex-col px-4 py-4 items-center gap-2  md:flex-row md:justify-evenly	 
                     sm:max-w-3xl md:max-w-full lg:max-w-full-4xl lg:mx-10"
        >
          <img
            className="h-[7rem] w-[7rem] md:h-[17rem] md:w-[17rem]"
            src="/assets/logo3.svg"
            alt="softfloor"
          />
          <div className="flex flex-col  gap-4">
            <div className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#FFFFFF"
                className="w-5 h-5 lg:h-6 lg:w-6"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 1L3 5v6c0 5.55 4.5 10.1 10 10.75 5.5-.65 10-5.2 10-10.75V5l-9-4zm-1 15h2v2h-2v-2zm0-4h2v4h-2v-4zm0-5.5h2v2h-2v-2z" />
              </svg>
              <p className="text-white text-base  lg:text-xl font-semibold whitespace-nowrap">
                ք.Երևան, Րաֆֆու փ. 39/29
              </p>
            </div>

            {/* phone */}
            <div className="flex  gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-5 h-5 lg:h-6 lg:w-6"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 
                     1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1"
                />
                <path d="M15 7a2 2 0 0 1 2 2" />
                <path d="M15 3a6 6 0 0 1 6 6" />
              </svg>
              <a
                href="tel:+37460691100"
                className=" text-white text-base lg:text-xl font-semibold whitespace-nowrap "
              >
                060 69 11 00
              </a>
            </div>
            {/* email */}
            <div className=" flex items-center  gap-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-5 h-5 lg:h-6 lg:w-6"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#ffffff"
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.89 2 1.99 2H20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
                />
              </svg>
              <a
                href="mailto:info@softfloor.am"
                className=" text-white text-base lg:text-xl font-semibold whitespace-nowrap"
              >
                info@softfloor.am
              </a>
            </div>
            <div className="flex items-center gap-2">
              <svg className="w-6 h-6 lg:h-6 lg:w-6" fill="#FFFFFF">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 
                     4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 
                     1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243
                     0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 
                     21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
              <a
                href="https://www.facebook.com/SoftFlooram"
                className="text-white text-base lg:text-xl font-semibold  whitespace-nowrap"
                alt="fb"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
      </motion.div>
      <AddressMap />
      <div className="w-full  flex items-center justify-center">
        <p className="text-white opacity-70 text-center text-sm	">
          &copy; SOFTFLOOR 2024 ԲՈԼՈՐ ԻՐԱՎՈՒՆՔՆԵՐԸ ՊԱՇՏՊԱՆՎԱԾ ԵՆ
        </p>
      </div>
    </div>
  );
};

export default Contact;
