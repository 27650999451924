import React from "react";
import { tileItems } from "../data/index";
import { motion } from "framer-motion";

export default function Tile() {
  return (
    <div className=" w-full h-full">
      <div className="flex justify-center items-center gap-8 flex-wrap mt-8 p-4">
        {tileItems.map((item) => {
          return (
            <motion.div
              key={item.id}
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                stiffness: 60,
                damping: 20,
                ease: "easeInOut",
                duration: 1,
              }}
            >
              <div
                className="max-w-sm h-[24rem] bg-black/75 rounded-md overflow-hidden 
                           shadow-lg flex flex-col items-center justify-end"
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                }}
              >
                <motion.div
                  className="px-6 py-4 bg-black/75"
                  initial={{ scale: 0.2 }}
                  animate={{ scale: 1 }}
                  transition={{
                    stiffness: 60,
                    damping: 20,
                    ease: "easeInOut",
                  }}
                >
                  <h4 className="font-bold flex  mb-2 text-white">
                    {item.title}
                  </h4>
                  <h6 className="font-bold   text-white">
                    {item.size ? item.size : null}
                  </h6>
                  <p className="text-gray-400/75 line-clamp-3 text-sm">
                    {item.description}
                  </p>
                </motion.div>
              </div>
            </motion.div>
          );
        })}
      </div>
      <div className="w-full flex items-center justify-center pb-2">
        <p className="text-white opacity-70 text-center text-sm	">
          &copy; SOFTFLOOR 2024 ԲՈԼՈՐ ԻՐԱՎՈՒՆՔՆԵՐԸ ՊԱՇՏՊԱՆՎԱԾ ԵՆ
        </p>
      </div>
    </div>
  );
}
