import React, { useState } from "react";
import { photos } from "../data/index";
import { PhotoAlbum } from "react-photo-album";
import { motion } from "framer-motion";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { Oval } from "react-loader-spinner";
import "../css/Seamless.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

function Seamless() {
  const [index, setIndex] = useState(-1);
  const [imageLoading, setImageLoading] = useState(true);

  const renderPhoto = ({
    layoutOptions,
    imageProps: { alt, style, ...restImageProps },
  }) => (
    <div
      style={{
        border: "2px solid #eee",
        borderRadius: "4px",
        boxSizing: "content-box",
        alignItems: "center",
        width: style?.width,
        padding: `${layoutOptions.padding - 2}px`,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
      }}
    >
      {imageLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Oval
            visible={true}
            height="80"
            width="80"
            color="gray"
            secondaryColor="white"
            ariaLabel="oval-loading"
          />
        </div>
      )}
      <motion.div
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 60,
          damping: 20,
          ease: "easeInOut",
          duration: 1,
        }}
      >
        <img
          alt={alt}
          style={{ ...style, width: "100%", padding: 0, cursor: "zoom-in" }}
          onLoad={() => setImageLoading(false)}
          onError={() => setImageLoading(false)}
          {...restImageProps}
        />
      </motion.div>
      <div
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
          overflow: "visible",
          whiteSpace: "nowrap",
        }}
      ></div>
    </div>
  );

  return (
    <div className="h-full w-full p-4">
      <div className=" w-full p-8 flex flex-col gap-8 ">
        <div className=" bg-black/50 md:px-6 py-4">
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              stiffness: 60,
              damping: 20,
              ease: "easeInOut",
              duration: 1,
            }}
            className=" flex flex-col gap-4  md:flex-row  md:gap-0 items-center justify-between xl:justify-around"
          >
            <p className="text-white text-lg md:text-xl px-4 md:px-0 md:w-1/2	">
              Մեր ընկերությունը միակն է Հայաստանում, որն օգտագործում է ռետինե
              ծածկույթների կառուցման ավտոմատ սարքավորումներ՝ մեծ մակերեսների
              համար: Արդյունքում ստացվում է ավելի հարթ և ամուր մակերես, ամբողջ
              մակերեսով ապահովվում է պատվիրատուի կողմից նշված հաստությունը
            </p>
            <img
              src={require("../assets/prich.jpg")}
              width="400"
              height="200"
              className="rounded-xl px-4 md:px-0"
              alt="prich"
            />
          </motion.div>
        </div>
        <PhotoAlbum
          photos={photos}
          layout="rows"
          spacing={20}
          padding={20}
          targetRowHeight={200}
          onClick={({ index }) => setIndex(index)}
          className="bg-black/75 w-1/3 "
          renderPhoto={renderPhoto}
        />

        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </div>
      <div className="w-full flex items-center justify-center ">
        <p className="text-white opacity-70 text-center text-sm	">
          &copy; SOFTFLOOR 2024 ԲՈԼՈՐ ԻՐԱՎՈՒՆՔՆԵՐԸ ՊԱՇՏՊԱՆՎԱԾ ԵՆ
        </p>
      </div>
    </div>
  );
}

export default Seamless;
