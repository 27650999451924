import React from "react";

const AddressMap = () => {
  return (
    <div className="relative overflow-hidden rounded-3xl  ">
      <a
        href="https://yandex.ru/maps/10262/yerevan/?utm_medium=mapframe&utm_source=maps"
        className="absolute top-0 text-white text-xs"
      ></a>
      <a
        href="https://yandex.ru/maps/10262/yerevan/house/YE0YcgNkQUEHQFpqfX12cnhmZw==/?ll=44.444881%2C40.173722&utm_medium=mapframe&utm_source=maps&z=18.23"
        className="absolute top-4 text-white text-xs"
      >
        Րաֆֆու փողոց, 39/29 — Yandex Maps
      </a>
      <iframe
        src="https://yandex.ru/map-widget/v1/?ll=44.444881%2C40.173722&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgo1NzAxNzM0ODQ3EjzVgNWh1bXVodW91b_VodW2LCDUtdaA1ofVodW2LCDVkNWh1obWhtW41oIg1oPVuNWy1bjWgSwgMzkvMjkiCg2ExzFCFZ2xIEI%2C&z=18.23"
        title="Yerevan Map"
        // width="555rem"
        // height="450"
        frameBorder="1"
        allowFullScreen="true"
        className="relative w-[22rem] h-[26rem] md:w-[43rem]  lg:w-[60rem] xl:w-[70rem] 2xl:w-[85rem] lg:h-[38rem]"
      ></iframe>
    </div>
  );
};

export default AddressMap;
