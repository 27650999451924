import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import "../css/Products.css";

const Products = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <motion.div
        className="w-full h-full flex flex-col  justify-center items-center gap-4
                   inset-0 place-items-center p-6  pr-7 px-8 md:px-8 lg:px-4 
                   lg:pb-6 sm:p-4"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 40,
          ease: "easeInOut",
          duration: 1,
        }}
      >
        <Typography
          variant="h3"
          color="white"
          className="m-6  text-center uppercase "
        >
          արտադրանքի մասին
        </Typography>
        <div className="flex  gap-4 flex-col lg:flex-row md:flex-col sm:flex-col ">
          {/* Card 1 */}
          <motion.div
            className="rounded-lg min-h-full"
            initial={{ scale: 0.2 }}
            animate={{ scale: 1 }}
            transition={{
              stiffness: 60,
              damping: 20,
              ease: "easeInOut",
            }}
          >
            <Card
              className="w-full max-w-[48rem] h-full flex flex-col md:flex-row "
              onClick={() => {
                if (isMobile) {
                  navigate("/retine_salik");
                }
              }}
            >
              <CardHeader
                shadow={false}
                floated={false}
                className="m-0 w-full shrink-0 rounded-r-none md:w-2/5 lg:w-2/5"
              >
                <img
                  src={require("../assets/products/retine_salik.jpg")}
                  alt="SoftFloor Rubber Tile"
                  className="h-full w-full  object-cover"
                />
              </CardHeader>
              <CardBody>
                <Typography
                  variant="h4"
                  color="gray"
                  className="mb-4 uppercase"
                >
                  ՌԵՏԻՆԵ ՍԱԼԻԿՆԵՐ
                </Typography>
                <Typography variant="h6" color="blue-gray" className="mb-2 ">
                  Ռետինե փշրանքներից պատրաստված սալիկները ամենապահանջված և
                  տարածում գտած ծածկույթներից են , որոնք նախատեսված են թե՛ բաց,
                  թե՛ փակ տարածքների համար
                </Typography>

                <Typography color="gray" className="mb-8 font-normal text-sm">
                  Soft Floor ռետինե սալիկները օգտագործվում են
                  խաղահրապարակներում, մարզասրահներում, մարզադաշտերում: <br />
                  Հատակի ռետինե սալիկները մեզ մոտ արտադրվում են սառը սեղմման
                  տեխնոլոգիայի կիրառմամբ: <br /> Բաղադրությունը՝ էկոլոգիապես
                  մաքուր ռետինե (SBR) կամ կաուչուկե (EPDM) փշրանքներ,
                  պոլիուրեթանային սոսինձ և պիգմենտային ներկ: Մենք օգտագործում
                  ենք միայն սերտիֆիկացված նյութեր:
                </Typography>
                <Button
                  variant="text"
                  className="prodBtn flex items-center  justify-center "
                  onClick={() => navigate("/retine_salik")}
                >
                  <div className="btn ">
                    <span
                      className="flip-front flex items-center justify-center gap-2 uppercase 
                                   font-bold whitespace-nowrap sm:px-2 sm:text-sm md:text-md
                                   lg:text-lg"
                    >
                      Տեսնել ավելին
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="cyan-700"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-4 w-4 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </span>
                    <span
                      className="flip-back flex items-center justify-center uppercase
                               font-bold whitespace-nowrap sm:px-2 sm:text-sm 
                               md:text-md lg:text-lg"
                    >
                      Ռետինե սալիկներ
                    </span>
                  </div>
                </Button>
              </CardBody>
            </Card>
          </motion.div>
          {/* Card2  */}
          <motion.div
            className="rounded-lg min-h-full"
            initial={{ scale: 0.2 }}
            animate={{ scale: 1 }}
            transition={{
              stiffness: 60,
              damping: 20,
              ease: "easeInOut",
            }}
          >
            <Card
              className="w-full max-w-[48rem]  flex flex-col md:flex-row h-full "
              onClick={() => {
                if (isMobile) {
                  navigate("/retine_hatak");
                }
              }}
            >
              <CardHeader
                shadow={false}
                floated={false}
                className="m-0 w-full  shrink-0 rounded-r-none md:w-2/5  "
              >
                <img
                  src={require("../assets/products/retine_hatak.jpg")}
                  alt="SoftFloor Rubber Seamless"
                  className="h-full w-full  object-cover"
                />
              </CardHeader>
              <CardBody>
                <Typography
                  variant="h4"
                  color="gray"
                  className="mb-4 uppercase"
                >
                  ռետինե հատակներ
                </Typography>
                <Typography variant="h6" color="blue-gray" className="mb-2">
                  Միաձույլ ռետինե հատակների պատրաստման ժամանակ մենք օգտագործում
                  ենք նախօրոք ներկված ռետինե փշրանքներ՝ որոնց առավելություններից
                  են
                </Typography>
                <Typography color="gray" className="mb-8 font-normal text-sm">
                  - ծածկույթները ստացվում են միաերանգ <br /> - աշխատանքների
                  կատարման ժամանակ չեն աղտոտվում առկա խաղասարքերն ու
                  մարզասարքերը, հարակից տարածքները <br />- ներկման հատուկ
                  տեխնոլոգիայի շնորհիվ առավել դիմացկուն են ուլտրամանուշակագույն
                  ճառագայթման հանդեպ <br />
                </Typography>
                <Button
                  variant="text"
                  className="prodBtn flex items-center  justify-center mt-7"
                  onClick={() => navigate("/retine_hatak")}
                >
                  <div className="btn ">
                    <span
                      className="flip-front flex items-center justify-center gap-2 uppercase  
                                   font-bold whitespace-nowrap sm:px-2 sm:text-sm md:text-md 
                                   lg:text-lg	"
                    >
                      Տեսնել ավելին
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="cyan-700"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-4 w-4 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </span>
                    <span
                      className="flip-back flex items-center justify-center uppercase 
                                   font-bold whitespace-nowrap sm:px-2 sm:text-sm md:text-md 
                                   lg:text-lg"
                    >
                      Ռետինե հատակներ
                    </span>
                  </div>
                </Button>
              </CardBody>
            </Card>
          </motion.div>
        </div>
        {/* Card3 */}
        <motion.div
          initial={{ scale: 0.2 }}
          animate={{ scale: 1 }}
          transition={{
            stiffness: 60,
            damping: 20,
            ease: "easeInOut",
          }}
        >
          <Card
            className="w-full md:max-w-[48rem] xl:max-w-[88rem] flex-col items-center
                     xl:flex-row lg:flex-col md:flex-col sm:flex-col "
            onClick={() => {
              if (isMobile) {
                navigate("/stone_carpet");
              }
            }}
          >
            <CardHeader className="m-0 w-full rounded-r-none md:w-2/5 lg:w-3/6   ">
              <img
                src={require("../assets/products/stoneCarpet.jpg")}
                alt="SoftFloor Rubber Crumb"
                className="h-full w-full object-cover xl:h-[618px]  "
              />
            </CardHeader>
            <CardBody className="w-full">
              <Typography
                variant="h4"
                color="gray"
                className="mb-4 text-center md:text-left uppercase"
              >
                ՔԱՐԵ ԳՈՐԳ եվ Քարե սալիկներ
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Քարե գորգերի և սալիկների արտադրությունը իրականացվում Է բնական
                քարերի տարբեր ֆրակցիաներով (2-ից 50 մմ), որոնք խառնվում են
                պոլիմերային նյութերի հետ և քսվում մակերեսին հավասար շերտով։
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Քարե գորգը և սալիկները էսթետիկ և ամուր ծածկույթներ են, որոնք
                օգտագործվում են տարբեր ոլորտներում.
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                -Կայանատեղեր, հեծանվային արահետներ, էկո-մայթերի տեղադրում.
                <br /> – Շենք-շինությունների մուտքեր, աստիճաններ, լողավազանի
                տարածքներ: <br />- Ցուցահանդեսային և առևտրի կենտրոններ. <br />-
                Ինտերիերի աշխատանքներ (սանհանգույց, լոգասենյակ, հյուրասենյակ,)
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Բնական քարից պատրաստված բացառիկ ձեռագործ սալիկներ
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Յուրաքանչյուր քարի գեղեցկությունը ժամանակակից տեխնոլոգիաների հետ
                համատեղ նոր մակարդակ է ստեղծում լանդշաֆտային դիզայնի մեջ:
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Ծակոտկեն կառուցվածքի շնորհիվ այն հուսալիորեն ամրացվում է սալիկի
                սոսինձով կամ հեղուկ մեխով:
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Մեր մշակած արտադրության տեխնոլոգիան թույլ է տալիս սալիկներին
                դիմակայել մեքենաների և հետիոտների ծանրություններին:
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Գերազանց մշակելիություն, հորատման և կտրելու հատկություններ:
                <br />
                Փակցման ժամանակ լրացուցիչ սարքավորումներ չեն պահանջվում:
                <br />
                Իդեալական է հորիզոնական և ուղղահայաց մակերևույթների ներքին և
                արտաքին հարդարման համար:
                <br /> Ջուրն անցնում է քարերի արանքով և հեռանում սալիկների
                տակով:
                <br /> Պատրաստի ծածկույթը հատուկ խնամք չի պահանջում:
              </Typography>
              <Typography color="gray" className=" font-normal text-sm">
                Բնական քարի ծածկույթի մակերևույթն ունի հաճելի և օրթոպեդիկ
                տեսանկյունից օգտակար հատկություններ:
              </Typography>
              <Typography color="gray" className="mb-8 font-normal text-sm">
                Սալիկների փայլուն ծածկույթը ստեղծում է թաց քարի էֆեկտ, որն
                ընդգծում է բնական նախշերը:
              </Typography>
              <Button
                variant="text"
                className="prodBtn flex items-center  justify-center"
                onClick={() => navigate("/stone_carpet")}
              >
                <div className="btn ">
                  <span
                    className="flip-front flex items-center justify-center gap-2 uppercase  
                                 font-bold whitespace-nowrap sm:px-2 sm:text-sm md:text-md 
                                 lg:text-lg"
                  >
                    Տեսնել ավելին
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="cyan-700"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      className="h-4 w-4 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </span>
                  <span
                    className="flip-back flex items-center justify-center uppercase 
                                 font-bold whitespace-nowrap sm:px-2 sm:text-sm md:text-md 
                                 lg:text-lg	"
                  >
                    ՔԱՐԵ ԳՈՐԳ եվ Քարե սալիկներ
                  </span>
                </div>
              </Button>
            </CardBody>
          </Card>
        </motion.div>
      </motion.div>
      <div className="w-full flex items-center justify-center pb-3">
        <p className="text-white opacity-70 text-center text-sm	">
          &copy; SOFTFLOOR 2024 ԲՈԼՈՐ ԻՐԱՎՈՒՆՔՆԵՐԸ ՊԱՇՏՊԱՆՎԱԾ ԵՆ
        </p>
      </div>
    </>
  );
};

export default Products;
