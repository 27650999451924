import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { navigation } from "../data/index";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full h-full sticky top-0 p-4 lg:p-2 z-50 transition
                  ease-in-out delay-150 duration-300 ${
                    isScrolled ? "bg-black/75" : ""
                  }`}
    >
      <div className="w-full max-w-96	flex items-center justify-between mr-8">
        {/* left section */}
        <div
          className="w-1/2 md:w-full lg:w-1/2 lg:flex 
                     justify-center items-center gap-4 md:gap-2 lg:gap-4 hidden md:flex "
        >
          <Link className="flex-shrink-0 hover:scale-105" to={"/"}>
            <img
              className="h-13 w-13  "
              src="/assets/logo3.svg"
              alt="softfloor"
            />
          </Link>
          <button
            className="bg-transparent font-sans  text-gray-300 text-xl md:text-sm 
                       lg:text-xl font-semibold hover:bg-black/75 hover:text-white 
                       hover:ring-2 hover:ring-white py-3 px-6 rounded-lg whitespace-nowrap uppercase"
            onClick={() => navigate("/")}
          >
            Գլխավոր էջ
          </button>
          <Menu open={openMenu} handler={setOpenMenu} allowHover>
            <MenuHandler>
              <button
                className="bg-transparent font-sans text-gray-300 flex items-center gap-3 text-xl 
                           md:text-sm lg:text-xl font-semibold  tracking-normal 
                           ring-0 hover:ring-2 hover:ring-white hover:bg-black/75
                            hover:text-white py-3 px-6 rounded-lg "
              >
                ՏԵՍԱԿԱՆԻ
                <ChevronDownIcon
                  strokeWidth={4}
                  className={`h-3.5 w-3.5  transition-transform ${
                    openMenu ? "rotate-180" : ""
                  }`}
                />
              </button>
            </MenuHandler>
            <MenuList
              className="bg-black/75 rounded-md text-gray-400
                         hover:text-white border-0"
            >
              {navigation.slice(1).map((item) => (
                <MenuItem
                  key={item.name}
                  className="px-3 py-2 rounded-md text-lg md:text-sm lg:text-lg font-medium uppercase"
                  onClick={() => navigate(`${item.to}`)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <button
            className="bg-transparent font-sans  text-gray-300 text-xl md:text-sm 
                       lg:text-xl font-semibold hover:bg-black/75 hover:text-white 
                       hover:ring-2 hover:ring-white py-3 px-6 rounded-lg whitespace-nowrap uppercase"
            onClick={() => navigate("/contact")}
          >
            ԿԱՊ{" "}
          </button>
        </div>
        {/* right section */}
        <div className="flex flex-row-reverse lg:flex-row w-1/2 items-center justify-center gap-4 pl-2">
          <div className="flex flex-col  lg:flex-row  items-center  justify-center lg:mt-1  gap-4 ">
            {/* phone */}
            <div className="  flex items-center   ">
              <img
                className="md:hidden h-12 w-12 mr-5 md:mr-0"
                src="/assets/logo3.svg"
                alt="softfloor"
                onClick={() => navigate("/")}
              />
              <div className="flex lg:items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#ffffff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-5 w-5 lg:h-7 lg:w-7"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 
                     1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1"
                  />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
                <a
                  href="tel:+37460691100"
                  className=" text-white text-base lg:text-2xl font-semibold whitespace-nowrap "
                >
                  060 69 11 00
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile menu button */}
      <div className="md:hidden">
        <button
          className="fixed top-5 right-3 z-50 bg-black/75 p-2 rounded-md text-gray-400
                     hover:text-white hover:ring-2 hover:ring-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open main menu</span>
          {isOpen ? (
            <XIcon className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
          )}
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="fixed inset-0  bg-black/75  justify-center items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                stiffness: 40,
                ease: "easeInOut",
                duration: 1,
              }}
            >
              <motion.div
                className="bg-black/75 w-full h-full flex justify-center"
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                exit={{ y: "-100vh" }}
                transition={{
                  stiffness: 40,
                  ease: "easeInOut",
                  duration: 1,
                }}
              >
                <div className="flex flex-col items-center justify-center overflow-y-scroll	">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white uppercase"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white uppercase",
                        "block px-3 py-2 rounded-md text-lg font-medium my-4  text-center "
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={() => setIsOpen(false)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
